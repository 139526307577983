import { Contract } from '@ethersproject/contracts';
import { atom } from 'recoil';

interface IMetadata {
	token_id: string;
	image: string;
	metadata: any;
}
export const walletConnectSate = atom<boolean>({
	key: 'walletConnect',
	default: true
});

export const packContractState = atom<Contract | null>({
	key: 'packContract',
	default: null
});

export const cardContractState = atom<any | null>({
	key: 'cardContract',
	default: null
});

export const packNFTsState = atom<IMetadata[]>({
	key: 'packNFTs',
	default: []
});
