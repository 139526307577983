import { Box, Button, Modal } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@mui/styles';
import DefaultCard from '../assets/images/defaultcard.png';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import 'animate.css';

const useStyles = makeStyles({
	cardBody: {
		display: 'flex',
		justifyContent: 'space-around',
		width: '100%',
		alignItems: 'center',
		'@media (max-width: 1099px)': {
			flexDirection: 'column'
		}
	},
	flex: {
		display: 'flex',
		'@media (max-width: 555px)': {
			flexDirection: 'column'
		}
	},
	card: {
		padding: '5px 5px'
	},
	cardImg: {
		width: '251px',
		height: '356px',
		cursor: 'pointer',
		background: 'black'
	},
	button: {
		marginTop: '30px !important',
		width: '200px',
		height: '40px',
		backgroundColor: '#FF4ABD!important',
		borderRadius: '20px',
		margin: '0 10px!important'
	},
	buttonLabel: {
		color: ' white',
		textTransform: 'none'
	}
});

const style = {
	position: 'absolute' as 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	maxWidth: 1000,
	maxHeight: 486,
	// width: '100%',
	// height: '100%',
	p: 4,
	display: 'flex',
	flexDirection: 'column' as 'column',
	justifyContent: 'center',
	alignItems: 'center',
	background: 'rgba(0,0,0,0.5)',
	overflow: 'auto',
	boxShadow: '0px 0px 10px grey'
};

interface ICard {
	id: number;
	img: string;
}

function BurnModal({ open, cardIds, handleClose, isBurning }) {
	const classes = useStyles();
	const [cards, setCards] = useState<ICard[]>([]);

	const vidRef = useRef<HTMLVideoElement>(null);

	useEffect(() => {
		if (vidRef) vidRef?.current?.play();
	}, []);

	useEffect(() => {
		if (cardIds) {
			let tmpCards = cardIds.map((id) => {
				return {
					id,
					img: DefaultCard
				};
			});
			setCards(tmpCards);
		}
	}, [cardIds]);

	const getImageURI = (tokenId: number) => {
		return `https://tunes.mypinata.cloud/ipfs/QmZNxaXwuP6oUFV49wGWLEFCEgMkc7UsQLibMCmjCwEbhc/${tokenId}.jpg`;
		// if (1 <= tokenId && tokenId <= 130) {
		// 	return 'cards/card1.png';
		// } else if (tokenId <= 195) {
		// 	return 'cards/card2.png';
		// } else if (tokenId <= 250) {
		// 	return 'cards/card3.png';
		// } else if (tokenId <= 325) {
		// 	return 'cards/card4.png';
		// } else {
		// 	return 'cards/card5.png';
		// }
	};
	const reveal = async (tokenId) => {
		try {
			const tmpCards = cards.map((card: ICard) => {
				if (card.id === tokenId) {
					return {
						...card,
						// img: imageURI.replace('ipfs://', 'https://ipfs.io/ipfs/')
						img: getImageURI(card.id)
					};
				}
				return card;
			});
			setCards(tmpCards);
		} catch (err) {}
	};

	const revealAll = () => {
		const tmpCards = cards.map((card: ICard) => {
			return {
				id: card.id,
				img: getImageURI(card.id)
			};
		});
		setCards(tmpCards);
	};
	if (isBurning) {
		return (
			// <img
			// 	src="burn.gif"
			// 	style={{
			// 		height: '100%',
			// 		width: '100%',
			// 		maxWidth: '300px',
			// 		maxHeight: '300px'
			// 	}}
			// 	alt="burn animation"
			// ></img>
			<video
				autoPlay={true}
				loop
				id="myVideo"
				ref={vidRef}
				muted
				style={{
					height: '100%',
					width: '100%',
					maxWidth: '800px',
					maxHeight: '800px'
				}}
			>
				<source
					src="burn.mp4"
					type="video/mp4"
				/>
			</video>
		);
	}
	return (
		<Modal
			open={open}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
			disableEscapeKeyDown={true}
			BackdropProps={{
				timeout: 500
			}}
		>
			<Box sx={style}>
				{cards.length > 0 && (
					<div className={classes.cardBody}>
						<div className={classes.flex}>
							<div className={classes.card}>
								<SwitchTransition mode="out-in">
									<CSSTransition
										key={`${
											cards[0].img === DefaultCard ? 'reveal' : 'default'
										}_index`}
										classNames={{
											enterActive: 'animate__animated animate__flipInY',
											exitActive: 'animate__animated animate__flipOutY'
										}}
										timeout={500}
									>
										<img
											src={cards[0].img}
											key={cards[0].id}
											alt="card"
											className={classes.cardImg}
											onClick={() => reveal(cards[0].id)}
										/>
									</CSSTransition>
								</SwitchTransition>
							</div>
							<div className={classes.card}>
								<SwitchTransition mode="out-in">
									<CSSTransition
										key={`${
											cards[1]?.img === DefaultCard ? 'reveal' : 'default'
										}_index`}
										classNames={{
											enterActive: 'animate__animated animate__flipInY',
											exitActive: 'animate__animated animate__flipOutY'
										}}
										timeout={500}
									>
										<img
											src={cards[1]?.img}
											key={cards[1]?.id}
											alt="card"
											className={classes.cardImg}
											onClick={() => reveal(cards[1]?.id)}
										/>
									</CSSTransition>
								</SwitchTransition>
							</div>

							<div className={classes.card}>
								<SwitchTransition mode="out-in">
									<CSSTransition
										key={`${
											cards[2]?.img === DefaultCard ? 'reveal' : 'default'
										}_index`}
										classNames={{
											enterActive: 'animate__animated animate__flipInY',
											exitActive: 'animate__animated animate__flipOutY'
										}}
										timeout={500}
									>
										<img
											src={cards[2]?.img}
											key={cards[2]?.id}
											alt="card"
											className={classes.cardImg}
											onClick={() => reveal(cards[2]?.id)}
										/>
									</CSSTransition>
								</SwitchTransition>
							</div>
						</div>
					</div>
				)}
				<div>
					<Button
						variant="contained"
						className={classes.button}
						onClick={() => revealAll()}
					>
						<span className={classes.buttonLabel}>Reveal All</span>
					</Button>
					<Button
						variant="contained"
						className={classes.button}
						onClick={() => handleClose()}
					>
						<span className={classes.buttonLabel}>Close</span>
					</Button>
				</div>
			</Box>
		</Modal>
	);
}

export default BurnModal;
