import * as React from 'react';
import { useWeb3React } from '@web3-react/core';
// import useSWR from 'swr';
import { Box, Button, Modal } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { injectedConnector, walletconnect } from '../connectors';
// import { fetcher } from '../functions';
// import ERC20ABI from '../abi/PackABI.json';
import { walletConnectSate } from '../lib/state';
import { useRecoilState } from 'recoil';

const useStyles = makeStyles({
	button: {
		width: '100%',
		marginTop: '10px !important',
		fontFamily: 'Brown',
		backgroundColor: '#973a87!important'
	},
	connectButton: {
		fontFamily: 'Brown'
	},
	accountInfo: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-end'
	},
	logoContainer: {
		flexGrow: 1,
		display: 'flex'
	},
	logoHref: {
		display: 'flex',
		textDecoration: 'none'
	},
	logo: {
		width: '36px',
		height: '36px',
		marginRight: '10px'
	},
	logoText: {
		fontSize: '20px',
		color: 'white',
		fontFamily: 'Brown',
		fontWeight: 'normal'
	}
});

const style = {
	position: 'absolute' as 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 400,
	bgcolor: '#4e3838',
	border: '2px solid #645050',
	boxShadow: 24,
	p: 4
};

export default function WalletConnect() {
	const classes = useStyles();

	const { chainId, activate, active, deactivate } = useWeb3React();
	const [isWalletConnected, setIsWalletConnected] =
		useRecoilState(walletConnectSate);
	const [open, setOpen] = React.useState<boolean>(false);

	React.useEffect(() => {
		if (chainId && chainId !== 5 && chainId !== 1) {
			alert('Wrong Network');
			deactivate();
		}
	}, [chainId, deactivate]);

	React.useEffect(() => {
		if (!isWalletConnected && !active) {
			setOpen(true);
		} else if (!isWalletConnected && active) {
			setIsWalletConnected(true);
		}
	}, [isWalletConnected, active, setIsWalletConnected]);

	// const onConnectWallet = () => {
	// 	setOpen(true);
	// };

	const onConnectMetaMask = () => {
		activate(injectedConnector);
		handleClose();
	};
	const onConnectWalletConnect = () => {
		activate(walletconnect);
		handleClose();
	};

	const handleClose = () => {
		setOpen(false);
		setIsWalletConnected(true);
	};

	return (
		<Box sx={{ flexGrow: 1 }}>
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={style}>
					<Button
						variant="contained"
						className={classes.button}
						onClick={onConnectMetaMask}
					>
						Connect MetaMask
					</Button>
					<Button
						variant="contained"
						className={classes.button}
						onClick={onConnectWalletConnect}
					>
						Connect WalletConnect
					</Button>
				</Box>
			</Modal>
		</Box>
	);
}
