import { useWeb3React } from '@web3-react/core';
import { useEffect, useMemo, useState } from 'react';

import { Contract } from '@ethersproject/contracts';
import { CardTokenAddress, PackTokenAddress } from '../constants';
import CardTokenABI from '../abi/CardABI.json';
import { Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { packNFTsState, walletConnectSate } from '../lib/state';
import { useRecoilState, useSetRecoilState } from 'recoil';
import BurnModal from '../components/BurnModal';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import React from 'react';
import axios from 'axios';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
	props,
	ref
) {
	return (
		<MuiAlert
			elevation={6}
			ref={ref}
			variant="filled"
			{...props}
		/>
	);
});

const useStyles = makeStyles({
	root: {
		display: 'flex',
		flexDirection: 'column',
		backgroundImage: (props: any) =>
			props.isBurning ? '' : 'url(/modal-background.png)',
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'cover',
		backgroundColor: 'black',
		backgroundPosition: 'center',
		width: '100%',
		height: '100%',
		justifyContent: 'center',
		alignItems: 'center',
		transition: 'opacity 11s ease-in-out'
	},
	body: {
		display: 'flex',
		backgroundImage: 'url(/modal.png)',
		width: '100%',
		maxWidth: '700px',
		height: '100%',
		maxHeight: '700px',
		position: 'relative',
		justifyContent: 'center'
	},

	nft: {
		textAlign: 'center',
		margin: '10px'
	},

	buttonContainer: {
		position: 'absolute',
		bottom: '40%',
		columnGap: '20px',
		display: 'flex'
	},
	button: {
		marginTop: '10px !important',
		width: '200px',
		height: '40px',
		backgroundColor: '#FF4ABD!important',
		borderRadius: '20px'
	},
	grayedButton: {
		marginTop: '10px !important',
		width: '200px',
		height: '40px',
		backgroundColor: '#918f8fd6!important',
		borderRadius: '20px'
	},
	buttonLabel: {
		color: ' white',
		textTransform: 'none'
	},
	buttonChestLabel: {
		color: ' white',
		textTransform: 'none',
		fontFamily: 'Brown'
	}
});

const Card = () => {
	const { account, library, chainId } = useWeb3React();
	const setIsWalletConnected = useSetRecoilState(walletConnectSate);
	const [packNfts, setPackNFTs] = useRecoilState(packNFTsState);
	const [isBurning, setIsBurning] = useState(false);
	const [isModal, setIsModal] = useState(false);
	const [isError, setIsError] = useState(false);
	const [mintedCardIds, setMintedCardIds] = useState<any[]>([]);
	const classes = useStyles({ isBurning });

	useEffect(() => {
		if (!account) {
			setIsWalletConnected(false);
		}
	}, [account, setIsWalletConnected]);

	useEffect(() => {
		if (account && process.env.REACT_APP_MORALIS_API_KEY) {
			axios
				.get(
					`https://deep-index.moralis.io/api/v2/${account}/nft/${PackTokenAddress}?chain=${
						chainId === 1 ? 'eth' : 'goerli'
					}`,
					{
						headers: {
							'X-API-KEY': process.env.REACT_APP_MORALIS_API_KEY
						}
					}
				)
				.then((response) => {
					setPackNFTs(response.data.result);
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [account, library, chainId]);

	const cardContract = useMemo(
		() =>
			library &&
			new Contract(CardTokenAddress, CardTokenABI, library.getSigner()),
		[library]
	);
	const burnPack = async () => {
		if (isBurning) {
			return;
		}
		if (!library) {
			setIsWalletConnected(false);
			return;
		}

		if (!packNfts || packNfts.length === 0) return;

		try {
			let tx = await cardContract.burnPack(
				Number(parseInt(packNfts[0].token_id)),
				{ gasLimit: 400000 }
			);
			setIsBurning(true);
			setIsModal(true);

			const recipient: any = await tx.wait();
			const cardIds: any[] = [];
			for (let i = 0; i < recipient.events.length; i++) {
				if (recipient.events[i].event === 'TransferSingle') {
					cardIds.push(Number(recipient.events[i].args[3]._hex));
				}
			}

			setMintedCardIds(cardIds);
			if (packNfts.length > 1) {
				setPackNFTs(packNfts.slice(1));
			} else {
				setPackNFTs([]);
			}
		} catch (error) {
			setIsModal(false);
			console.log(error);
			setIsError(true);
			setTimeout(() => {
				setIsError(false);
			}, 2000);
		}
		setIsBurning(false);
	};

	const handleClose = () => {
		setIsModal(false);
	};

	const openWindow = (url) => {
		(window as any)?.open(url, '_blank').focus();
	};
	return (
		<div className={classes.root}>
			{!isModal && (
				<div className={classes.buttonContainer}>
					<Button
						variant="contained"
						className={
							packNfts.length === 0 ? classes.grayedButton : classes.button
						}
						onClick={() => burnPack()}
						disabled={packNfts.length === 0}
					>
						<span className={classes.buttonLabel}>OPEN CHEST</span>
					</Button>

					<Button
						variant="contained"
						className={classes.button}
						onClick={() => openWindow('https://codex.capsulehouse.io/gallery')}
					>
						<span className={classes.buttonChestLabel}>CHCC: GALLERY</span>
					</Button>
				</div>
			)}

			<BurnModal
				open={isModal}
				cardIds={mintedCardIds}
				handleClose={handleClose}
				isBurning={isBurning}
			/>
			<Snackbar
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
				open={isError}
				onClose={handleClose}
				message="I love snacks"
				style={{ marginTop: '45px' }}
			>
				<Alert severity="error">Failed to burn. Please try again</Alert>
			</Snackbar>
		</div>
	);
};

export default Card;
