import { makeStyles } from '@mui/styles';
import { useWeb3React } from '@web3-react/core';
import axios from 'axios';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { packNFTsState, walletConnectSate } from '../lib/state';
import { PackTokenAddress, Bubble1Text, Bubble2Text } from '../constants';

const useStyles = makeStyles({
	root: {
		display: 'flex',
		flexDirection: 'column',
		width: '100vw',
		justifyContent: 'center',
		alignItems: 'center',
		overflow: 'auto'
	},

	buttonLabel: {
		color: ' white',
		textTransform: 'none'
	},
	backgroundVideo: {
		objectFit: 'cover',
		width: '100vw',
		height: '100vh',
		top: 0,
		left: 0
	},
	backgroundVideoLight: {
		objectFit: 'cover',
		width: '100vw',
		height: '100vh',
		top: 0,
		left: 0
	},
	bubbleContainer: {
		position: 'absolute',
		fontFamily: 'Komikula',
		color: 'white',
		width: '500px',
		height: '300px',
		marginLeft: '5%',
		'@media (max-width: 510px)': {
			width: '100%',
			marginLeft: '0'
		},
		'@media (max-width: 400px)': {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center'
		}
	},
	frogContainer: {
		position: 'absolute',
		fontFamily: 'Komikula',
		color: 'white',
		width: '300px',
		height: '300px',
		marginLeft: '-40%',
		'@media (max-width: 510px)': {
			marginLeft: '0'
		}
	},

	buttonContainer: {
		position: 'absolute',
		width: '250px',
		height: '180px'
	},

	bubble1Body: {
		fontFamily: 'Komikula',
		color: 'white',
		padding: '5px',
		width: 'calc(100% - 40px)',
		height: 'calc(100% - 20px)',
		boxSizing: 'border-box',
		position: 'absolute',
		top: '25px',
		left: '19px'
	},
	bubble2Body: {
		fontFamily: 'Komikula',
		color: 'white',
		padding: '5px',
		width: 'calc(100% - 70px)',
		height: 'calc(100% - 48px)',
		boxSizing: 'border-box',
		position: 'absolute',
		top: '30px',
		left: '34px'
	},
	frogBody: {
		fontFamily: 'Komikula',
		color: 'white',
		padding: '5px',
		width: 'calc(100% - 40px)',
		height: 'calc(100% - 20px)',
		boxSizing: 'border-box',
		position: 'absolute',
		top: '31px',
		left: '19px'
	},
	connectButton: {
		fontFamily: 'Brown'
	},
	hereLink: {
		color: '#00b8ff'
	}
});

function getWindowDimensions() {
	const { innerWidth: width, innerHeight: height } = window;
	return {
		width,
		height
	};
}

const TentAreaRate = [
	{
		x: 1004 / 1519,
		y: 140 / 929
	},
	{
		x: 1,
		y: 238 / 929
	},
	{
		x: 1,
		y: 630 / 929
	},
	{
		x: 1184 / 1519,
		y: 656 / 929
	},
	{
		x: 1164 / 1519,
		y: 809 / 929
	},
	{
		x: 882 / 1519,
		y: 747 / 929
	}
];

const isInside = (L, polyPoints) => {
	var x = L.x,
		y = L.y;
	var intersections = 0;
	var ss = '';

	for (var i = 0, j = polyPoints.length - 1; i < polyPoints.length; j = i++) {
		var xi = polyPoints[i].x,
			yi = polyPoints[i].y;
		var xj = polyPoints[j].x,
			yj = polyPoints[j].y;
		if (yj === yi && yj === y && x > Math.min(xj, xi) && x < Math.max(xj, xi)) {
			// Check if point is on an horizontal polygon boundary
			return true;
		}

		if (
			y > Math.min(yj, yi) &&
			y <= Math.max(yj, yi) &&
			x <= Math.max(xj, xi) &&
			yj !== yi
		) {
			ss = ((y - yj) * (xi - xj)) / (yi - yj) + xj;
			if (ss === x) {
				// Check if point is on the polygon boundary (other than horizontal)
				return true;
			}

			if (xj === xi || x <= ss) {
				intersections++;
			}
		}
	}
	// If the number of edges we passed through is odd, then it’s in the polygon.
	if (intersections % 2 !== 0) {
		return true;
	} else {
		return false;
	}
};

const Home = () => {
	const classes = useStyles();
	const { account, library, chainId } = useWeb3React();
	const vidRef = useRef<HTMLVideoElement>(null);
	const vidHoverRef = useRef<HTMLVideoElement>(null);
	const [windowDimensions, setWindowDimensions] = useState(
		getWindowDimensions()
	);
	const [text2Interval, setText2Interval] = useState<any>();
	const [isOverTent, setIsOverTent] = useState(false);
	const setIsWalletConnected = useSetRecoilState(walletConnectSate);
	const [packNFTs, setPackNFTs] = useRecoilState(packNFTsState);
	const navigate = useNavigate();

	useEffect(() => {
		if (vidRef) vidRef?.current?.play();
		if (vidHoverRef) vidHoverRef?.current?.play();
	}, []);

	useEffect(() => {
		if (account && process.env.REACT_APP_MORALIS_API_KEY) {
			axios
				.get(
					`https://deep-index.moralis.io/api/v2/${account}/nft/${PackTokenAddress}?chain=${
						chainId === 1 ? 'eth' : 'goerli'
					}`,
					{
						headers: {
							'X-API-KEY': process.env.REACT_APP_MORALIS_API_KEY
						}
					}
				)
				.then((response) => {
					if (response.data.result.length === 0) {
						const frogInstance = document.getElementById('frogMessage');
						if (frogInstance) frogInstance.style.display = 'block';

						const bubble1 = document.getElementById('bubble1');
						if (bubble1) bubble1.style.display = 'none';
					}
					setPackNFTs(response.data.result);
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [account, library, chainId]);

	useEffect(() => {
		const btnObj = document.getElementById('btnEnter');

		if (btnObj) {
			let x;
			if (windowDimensions.width >= 1100)
				x = 650 - (1900 / windowDimensions.width) * 210;
			else if (windowDimensions.width >= 900)
				x = 500 - (1900 / windowDimensions.width) * 100;
			else if (windowDimensions.width >= 710) {
				x = 200;
				btnObj.style.top = '50px';
			} else {
				x = 0;
				btnObj.style.top = '170px';
			}

			btnObj.style.left = x + 'px';
		}
	}, [windowDimensions]);

	useEffect(() => {
		function handleResize() {
			setWindowDimensions(getWindowDimensions());
		}

		window.addEventListener('resize', handleResize);

		const displayBubble = () => {
			const bubble1Body = document.getElementById('bubble1Body');
			const bubble1 = document.getElementById('bubble1');

			if (bubble1Body && bubble1) {
				bubble1.style.display = 'block';

				const textInterval = setInterval(() => {
					if (bubble1Body.innerHTML.length < Bubble1Text.length) {
						bubble1Body.innerHTML =
							bubble1Body.innerHTML +
							Bubble1Text.charAt(bubble1Body.innerHTML.length);
					} else {
						const connectInstance = document.getElementById('walletConnect');
						if (connectInstance) connectInstance.style.display = 'block';
					}
				}, 50);

				setTimeout(() => {
					clearInterval(textInterval);
				}, 43 * 60);
			}
		};

		setTimeout(() => {
			displayBubble();
		}, 300);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const vidObj = document.getElementById('background');
		const vidLightObj = document.getElementById('backgroundLight');
		const btnObj = document.getElementById('btnEnter');
		const bubble2Body = document.getElementById('bubble2Body');
		const bubble2 = document.getElementById('bubble2');
		let textInterval;

		if (isOverTent && packNFTs.length !== 0) {
			const frogMessage = document.getElementById('frogMessage');
			if (frogMessage) frogMessage.style.display = 'none';

			if (vidObj && vidLightObj && btnObj) {
				vidObj.style.display = 'none';
				vidLightObj.style.display = 'block';
				btnObj.style.display = 'block';
			}

			if (bubble2 && bubble2Body) {
				bubble2.style.display = 'block';

				textInterval = setInterval(() => {
					bubble2Body.innerHTML =
						bubble2Body.innerHTML +
						Bubble2Text.charAt(bubble2Body.innerHTML.length);
				}, 50);
				setText2Interval(textInterval);

				setTimeout(() => {
					clearInterval(textInterval);
				}, 90 * 60);
			}
		} else {
			if (vidObj && vidLightObj && btnObj) {
				vidLightObj.style.display = 'none';
				vidObj.style.display = 'block';
				btnObj.style.display = 'none';
			}
			if (bubble2 && bubble2Body) {
				bubble2.style.display = 'none';
				bubble2Body.innerHTML = '';
			}
			if (text2Interval) {
				clearInterval(text2Interval);
				setText2Interval(null);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isOverTent, packNFTs]);

	useEffect(() => {
		const mouseMoveHandler = (event) => {
			const tentArea = TentAreaRate.map((position) => ({
				x: position.x * windowDimensions.width,
				y: position.y * windowDimensions.height
			}));
			const isTent = isInside({ x: event.x, y: event.y }, tentArea);
			if (isTent && account) {
				setIsOverTent(true);
			} else {
				setIsOverTent(false);
			}
		};

		document.addEventListener('mousemove', mouseMoveHandler);

		return () => {
			document.removeEventListener('mousemove', mouseMoveHandler);
		};
	}, [account, navigate, windowDimensions]);
	return (
		<div className={classes.root}>
			<video
				autoPlay={true}
				loop
				id="backgroundLight"
				ref={vidHoverRef}
				muted
				className={classes.backgroundVideoLight}
			>
				<source
					src="background_hover.MP4"
					type="video/mp4"
				/>
			</video>
			<video
				autoPlay={true}
				loop
				id="background"
				ref={vidRef}
				muted
				className={classes.backgroundVideo}
			>
				<source
					src="background.MP4"
					type="video/mp4"
				/>
			</video>
			<div
				className={classes.buttonContainer}
				onClick={() => navigate('/burn')}
			>
				<img
					id="btnEnter"
					className={'fadeButton'}
					src="/Button.png"
					alt="enter"
				/>
			</div>
			<div className={classes.frogContainer}>
				<div
					className={'frogMessage'}
					id="frogMessage"
				>
					<div className={classes.frogBody}>
						Sorry Fren, nothing here for you. Try{' '}
						<a
							href="https://opensea.io/collection/chcc-genesis-chest/drop"
							target={'_blank'}
							rel="noopener noreferrer"
							className={classes.hereLink}
						>
							here
						</a>
						.
					</div>
				</div>
			</div>
			<div className={classes.bubbleContainer}>
				<div
					className={'bubble1'}
					id="bubble1"
				>
					<div
						className={classes.bubble1Body}
						id="bubble1Body"
					></div>
				</div>
				{!account && (
					<div
						// src={ConnectImg}
						id="walletConnect"
						onClick={() => setIsWalletConnected(false)}
						className={'walletConnect'}
						// alt="wallet connect"
					>
						{/* <span className={classes.connectButton}>Connect Wallet</span> */}
					</div>
				)}

				<div
					className={'bubble2'}
					id="bubble2"
				>
					<div
						className={classes.bubble2Body}
						id="bubble2Body"
					></div>
				</div>
			</div>
		</div>
	);
};

export default Home;
