import { Web3ReactProvider } from '@web3-react/core';
import { Routes, Route } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import Burn from './pages/burn';
import Home from './pages/home';
import { getLibrary } from './functions';
import WalletConnect from './components/WalletConnect';
function App() {
	return (
		<Web3ReactProvider getLibrary={getLibrary}>
			<RecoilRoot>
				<WalletConnect />
				<div className="App">
					<div className="container">
						<Routes>
							<Route
								path="/"
								element={<Home />}
							/>

							<Route
								path="/burn"
								element={<Burn />}
							/>
						</Routes>
					</div>
				</div>
			</RecoilRoot>
		</Web3ReactProvider>
	);
}

export default App;
