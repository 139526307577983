import { InjectedConnector } from '@web3-react/injected-connector';
import { WalletConnectConnector } from '@web3-react/walletconnect-connector';

const RPC_URLS: { [chainId: number]: string } = {
	1: process.env.REACT_APP_RPC_URL_1 as string,
	5: process.env.REACT_APP_RPC_URL_5 as string
};

export const injectedConnector = new InjectedConnector({
	supportedChainIds: [1, 5]
});

export const walletconnect = new WalletConnectConnector({
	rpc: { 1: RPC_URLS[1] },
	qrcode: true
});
